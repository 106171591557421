@import "styles/colors.scss";

.Toastify__toast-container {
  .Toastify__toast--success {
    background-color: $green;
  }
  .Toastify__toast--error {
    background-color: $red;
  }
  .Toastify__toast--info {
    background-color: $blue;
  }
  .Toastify__toast-body {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .Toastify__close-button {
    display: none;
  }
}
