@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "styles/colors.scss";

@font-face {
  font-family: MuseoSans;
  src: url("./assets/fonts/MuseoSans-100.otf") format("opentype");
}

@font-face {
  font-family: MuseoSans;
  font-weight: 300;
  src: url("./assets/fonts/MuseoSans-300.otf") format("opentype");
}
@font-face {
  font-family: MuseoSans;
  font-weight: 500;
  src: url("./assets/fonts/MuseoSans-500.otf") format("opentype");
}
@font-face {
  font-family: MuseoSans;
  font-weight: 700;
  src: url("./assets/fonts/MuseoSans-700.otf") format("opentype");
}
@font-face {
  font-family: MuseoSans;
  font-weight: 900;
  src: url("./assets/fonts/MuseoSans-900.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: MuseoSans, sans-serif;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $indigo;
  .wrapper {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  .wrapper-lg {
    width: 100%;
    max-width: 1228px;
    margin: 0 auto;
  }
}

#root {
  height: 100%;
}

img {
  max-width: 100%;
  margin: 0;
}

a {
  text-decoration: underline;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.smooth-scroll {
  scroll-behavior: smooth;
}

@media (max-width: 340px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 650px) {
  html {
    font-size: 60%;
  }
}

@media (min-width: 769px) {
  html {
    font-size: 32%;
    body {
      .wrapper {
        max-width: calc(100vw / 2);
      }
    }
    .login-card {
      width: 100%;
      max-width: 520px;
      margin: 0 auto;
    }

    @media (min-width: 800px) {
      .login-card {
        max-width: calc((35 / 100) * 797px);
      }
    }

    @media (min-width: 1000px) {
      .login-card {
        max-width: calc((50 / 100) * 797px);
      }
    }

    @media (min-width: 1200px) {
      .login-card {
        max-width: calc((55 / 100) * 797px);
      }
    }

    @media (min-width: 1400px) {
      .login-card {
        max-width: calc((60 / 100) * 797px);
      }
    }

    @media (min-width: 1600px) {
      .login-card {
        max-width: calc((65 / 100) * 797px);
      }
    }

    @media (min-width: 1900px) {
      .login-card {
        max-width: calc((80 / 100) * 797px);
      }
    }

    @media (min-width: 2000px) {
      .login-card {
        max-width: calc((85 / 100) * 797px);
      }
    }

    @media (min-width: 2500px) {
      .login-card {
        max-width: 797px;
      }
    }

    @media (min-width: 3400px) {
      .login-card {
        max-width: calc((150 / 100) * 797px);
      }
    }

    @media (min-width: 5000px) {
      .login-card {
        max-width: calc((160 / 100) * 797px);
      }
    }

    @media (min-width: 6000px) {
      .login-card {
        max-width: calc((300 / 100) * 797px);
      }
    }
  }
}

@media (min-width: 800px) {
  html {
    font-size: 35%;
  }
}

@media (min-width: 1000px) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 55%;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 60%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 65%;
  }
}

@media (min-width: 1900px) {
  html {
    font-size: 80%;
  }
}

@media (min-width: 2000px) {
  html {
    font-size: 85%;
  }
}

@media (min-width: 2500px) {
  html {
    font-size: 100%;
  }
}

@media (min-width: 3400px) {
  html {
    font-size: 150%;
  }
}

@media (min-width: 5000px) {
  html {
    font-size: 160%;
  }
}

@media (min-width: 6000px) {
  html {
    font-size: 300%;
  }
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 500ms;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms;
}
.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: all 500ms;
}
