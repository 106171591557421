@import "styles/colors.scss";

.ma_container {
  .ma_image {
    opacity: 1;
    transform: scale(1);
  }
  .text_container {
    opacity: 1;
    transform: scale(1);
    transform-origin: left;
    p {
      font-weight: 500;
      font-size: 1.4rem;
      color: $grey-900;
      margin-top: 0.8rem;
      @media (min-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }
  .animate_ma_image {
    animation-name: animate_ma_image;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .animate_content {
    animation-name: animate_content;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
  }
}

@keyframes animate_ma_image {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animate_content {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
