.timeslot-reminder-container {
  .timeslot-dialog {
    max-width: 33rem;
    opacity: 0;
    transform: scale(0);
    border-radius: 50%;
    transform-origin: bottom right;
  }
  .show-timeslot-icon {
    opacity: 1;
    transition: 200ms ease-out opacity 200ms;
  }

  .hide-timeslot-icon {
    opacity: 0;
    transition: 200ms ease-out opacity 100ms;
  }

  .show-timeslot-dialog {
    animation-name: scale-anim;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hide-timeslot-dialog {
    animation-name: scaledown-anim;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }
  // @media (max-width: 768px) {
  //   display: none;
  // }
}

@keyframes scale-anim {
  to {
    opacity: 1;
    border-radius: 0.7rem;
    transform: scale(1);
  }
}

@keyframes scaledown-anim {
  from {
    opacity: 1;
    border-radius: 0.7rem;
    transform: scale(1);
  }
  to {
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
  }
}

//mobile

.timeslot-icon-mobile {
  @media (min-width: 769px) {
    display: none;
  }
}
