.mySwiper {
    height: 240px;
}
.swiper {
   z-index: 10;
}
.swiper-slide {
    font-size: 18px;
    background: #fff;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-pagination{
    position: static;
    margin-top: 30px;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: gray;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #ff8548;
}
.mySwiper {
    position: relative;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
}

.swiper-button-prev::before,
.swiper-button-next::before {
    content: "";
    width: 12px;
    height: 12px;
    border-top: 2px solid white;
    border-left: 2px solid white;
    transform: rotate(-45deg);
}

.swiper-button-next::before {
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
}