@import "styles/colors.scss";

.modal_component {
  z-index: 999;
  .modal_component_children_container {
    animation-name: slide-y;
    animation-duration: 0.4s;
    transform: translateY(100%);
    animation-fill-mode: forwards;
  }
  .modal_component_children_container_reverse {
    animation-name: slide-reverse;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }
}

@-webkit-keyframes slide-y {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
  }
}

@keyframes slide-y {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
  }
}

@-webkit-keyframes slide-reverse {
  from {
    -webkit-transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
  }
}

@keyframes slide-reverse {
  from {
    -webkit-transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
  }
}
