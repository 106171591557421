@import "styles/colors.scss";
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.custom_input {
  .special-label {
    display: none;
  }
  input,
  select {
    @apply bg-transparent;
    @apply border;
    @apply font-medium;
    @apply h-18;
    @apply text-2xl;
    @apply w-full;
    @apply border-grey-600;
    -webkit-appearance: none;
    border-radius: 4px;
    outline: none;
    padding-left: 12px;
    padding-right: 4px;
    color: $indigo;
    &:focus {
      border: 1px solid $indigo;
    }
    &:focus-within {
      border: 1px solid $indigo;
    }
    &::-webkit-input-placeholder {
      color: $cloudyBlue;
    }
    &::-moz-placeholder {
      color: $cloudyBlue;
    }
    &:-ms-input-placeholder {
      color: $cloudyBlue;
    }
    &::-ms-input-placeholder {
      color: $cloudyBlue;
    }
    &::placeholder {
      color: $cloudyBlue;
    }
  }

  .date-picker-class {
    @apply border;
    @apply border-grey-600;
    border-radius: 4px;
    .react-date-picker__wrapper {
      padding-left: 2px;
      padding-right: 10px;
      border: none;
      input {
        border: none;
      }
      input:focus {
        border-radius: 0px;
        border-bottom: 0.1rem solid #101347;
      }
    }
    .react-date-picker__inputGroup__divider {
      font-size: 1.5rem;
      padding: 0 4px;
    }
    .react-date-picker__inputGroup__leadingZero {
      font-size: 1.5rem;
      font-weight: 500;
      color: $indigo;
    }
    .react-date-picker__button {
      padding: 1rem;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  .invalid {
    @apply border;
    @apply border-errorRed;
  }
  .react-date-picker__calendar {
    width: 45rem;
    .react-calendar {
      width: 45rem;
      border: 2px solid $borderColor;
      border-radius: 10px;
      box-shadow: 0px 5px 10px rgba($color: #000000, $alpha: 0.1);
      .react-calendar__navigation {
        height: 4.4rem;
        .react-calendar__navigation__arrow {
          min-width: 4.4rem;
          font-size: 2.5rem;
          font-weight: 500;
        }
        .react-calendar__navigation__label {
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
      .react-calendar__viewContainer {
        padding: 1rem;
        padding-bottom: 2rem;

        abbr {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .react-calendar__month-view__weekdays {
          padding-bottom: 1.2rem;
          abbr {
            text-decoration: none;
            text-transform: capitalize;
            color: rgba($color: $indigo, $alpha: 0.4);
          }
        }
        .react-calendar__tile {
          font-size: 1.3rem;
          font-size: 1.5rem;
          font-weight: 500;
        }
        .react-calendar__tile--active {
          background: $orange;
          font-weight: 700;
          border-radius: 4px;
          box-shadow: 0px 5px 10px rgba($color: $orange, $alpha: 0.3);
        }

        .react-calendar__tile--now {
          color: royalblue;
          background-color: transparent;
        }

        .react-calendar__tile--hasActive {
          background: $orange;
          color: white;
          font-weight: 700;
          border-radius: 4px;
          box-shadow: 0px 5px 10px rgba($color: $orange, $alpha: 0.3);
        }
        .react-calendar__tile:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }
}

.custom_pass {
  .special-label {
    display: none;
  }
  &_field {
    -webkit-appearance: none;
    border-radius: 0;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply relative;
    @apply bg-transparent;
    @apply border-solid;
    @apply border-b-2;
    @apply h-16;
    @apply w-full;
    @apply text-3xl;
    border-color: $borderColor;
    color: $indigo;

    &:focus-within {
      border-bottom: 0.1rem solid $indigo;
    }

    input {
      @apply flex-1;
      @apply font-medium;
      outline: none;

      &::-webkit-input-placeholder {
        color: $cloudyBlue;
      }

      &::-moz-placeholder {
        color: $cloudyBlue;
      }

      &:-ms-input-placeholder {
        color: $cloudyBlue;
      }

      &::-ms-input-placeholder {
        color: $cloudyBlue;
      }

      &::placeholder {
        color: $cloudyBlue;
      }
    }
    padding-left: 12px;
    border-radius: 6px;
    @apply border;
    @apply border-grey-600;
    padding-left: 12px;
    padding-right: 10px;

    @apply text-2xl;
    &:focus-within {
      border: 0.1rem solid $indigo;
    }
    &_invalid {
      @apply border-b md:border;
      @apply border-errorRed;
    }
  }
  .invalid {
    @apply border-b md:border;
    @apply border-errorRed;
  }
}

.required-label label:after {
  content: " *";
  color: red;
}

.required-label label[for="address_2"]:after {
  content: "";
}
//This one can be used by adding required prop in <input/>
.required-field::after {
  content: " *";
  color: red;
}
