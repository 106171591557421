$blue: #53c2e4;
$orange: #ff8548;
$indigo: #101347;
$grey-900: #515151;
$borderColor: rgb(235, 235, 235);
$alternativeWhite: rgb(249, 249, 249);
$white: #ffff;
$black: #0000;
$red: rgb(255, 52, 52);
$green: #1ace76;
$cloudyBlue: rgb(179, 196, 201);
