[data-braintree-id="choose-a-way-to-pay"] {
  display: none;
}
.braintree-placeholder {
  display: none;
}

// .braintree-option {
//   padding: 1.5rem 1rem;
//   .braintree-option__logo {
//     svg {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .braintree-option__label {
//     font-size: 1.5rem !important;
//     font-weight: 700;
//     color: #101347;
//     margin-left: 2rem !important;
//   }
// }
// .braintree-sheet__container {
//   .braintree-sheet__header {
//     .braintree-sheet__logo--header {
//       width: 6rem;
//       height: 3rem;
//       svg {
//         width: 100%;
//         height: 100%;
//       }
//     }
//     .braintree-sheet__header-label {
//       .braintree-sheet__text {
//         font-size: 1.5rem !important;
//         font-weight: 700;
//         color: #101347;
//       }
//     }
//     .braintree-sheet__icons {
//       .braintree-sheet__card-icon {
//         width: 4rem;
//         height: 2rem;

//         svg {
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }
//   }
//   .braintree-sheet__content {
//     .braintree-form__field-group {
//       .braintree-form__label {
//         font-size: 1.2rem !important;
//         font-weight: 500;
//         color: #101347;
//       }
//       .braintree-form__field {
//         .braintree-form-number {
//           height: 3rem;
//           margin-top: 0.4rem;
//           padding: 0px 0.8rem !important;
//           font-size: 1.2rem !important;
//           iframe {
//             input {
//               font-size: 1.2rem !important;
//               color: #101347;
//               &::placeholder {
//                 font-size: 1.2rem !important;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
