.pharmacy_list_view-up {
  animation-name: slide-up;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}
.pharmacy_list_view-down {
  animation-name: slide-down;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
