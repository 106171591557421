.visit_sub_question_slide_up_container {
  animation-name: slide-up;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
