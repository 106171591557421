.pharmacy_details_container {
  animation-name: zoom-in;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  transform-origin: center;
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
