.bubble_content_provider {
  * {
    all: unset !important;
    font-size: 1.5rem !important;
    color: white !important;
    font-weight: 500 !important;
    font-family: "MuseoSans" !important;
    overflow-wrap: break-word !important;
  }
  a {
    color: rgb(6, 69, 173) !important;
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}

.bubble_content_user {
  * {
    all: unset !important;
    font-size: 1.5rem !important;
    color: #101347 !important;
    font-weight: 500 !important;
    font-family: "MuseoSans" !important;
    overflow-wrap: break-word !important;
  }
  a {
    color: rgb(6, 69, 173) !important;
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}
